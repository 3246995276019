var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-approve-deposit","cancel-variant":"outline-secondary","ok-title":_vm.$t('Submit'),"cancel-title":_vm.$t('Close'),"ok-variant":"success","centered":"","title":_vm.$t('Approve deposit transaction'),"modal-class":"modal-success","no-close-on-backdrop":true},on:{"ok":_vm.confirmApproveDeposit,"hidden":_vm.resetForm}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validationFormApproveDeposit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"transactionId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Transaction id'),"label-for":"transactionId"}},[_c('b-form-input',{attrs:{"id":"name","trim":"","disabled":""},model:{value:(_vm.deposit.reference),callback:function ($$v) {_vm.$set(_vm.deposit, "reference", $$v)},expression:"deposit.reference"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Username'),"label-for":"transactionId"}},[_c('b-form-input',{attrs:{"id":"name","trim":"","disabled":""},model:{value:(_vm.deposit.username),callback:function ($$v) {_vm.$set(_vm.deposit, "username", $$v)},expression:"deposit.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('Amount')) + " (Gross)"),"label-for":"transactionId"}},[_c('b-form-input',{attrs:{"id":"amount","trim":"","disabled":""},model:{value:(_vm.deposit.reviewAmount),callback:function ($$v) {_vm.$set(_vm.deposit, "reviewAmount", $$v)},expression:"deposit.reviewAmount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"remark"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"remark"}},[_c('label',{attrs:{"for":"remark"}},[_c('span',{staticClass:"text-danger"}),_vm._v(" "+_vm._s(_vm.$t('Remark')))]),_c('b-form-textarea',{attrs:{"id":"remark","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.deposit.remark),callback:function ($$v) {_vm.$set(_vm.deposit, "remark", $$v)},expression:"deposit.remark"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }