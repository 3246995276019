<template>
	<div>
		<b-modal
			id="modal-approve-deposit"
			cancel-variant="outline-secondary"
			:ok-title="$t('Submit')"
			:cancel-title="$t('Close')"
			ok-variant="success"
			centered
			:title="$t('Approve deposit transaction')"
			modal-class="modal-success"
			:no-close-on-backdrop="true"
			@ok="confirmApproveDeposit"
			@hidden="resetForm"
		>
			<!-- form -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<b-form
					@submit.prevent="handleSubmit(validationFormApproveDeposit)"
					@reset.prevent="resetForm"
				>

					<b-row>
						<!-- Transaction Id -->
						<b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="transactionId"
								rules="required"
							>
								<b-form-group
									:label="$t('Transaction id')"
									label-for="transactionId"
								>
									<b-form-input
										id="name"
										v-model="deposit.reference"
										trim
										disabled
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col>

						<!-- Username -->
						<b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="username"
								rules="required"
							>
								<b-form-group
									:label="$t('Username')"
									label-for="transactionId"
								>
									<b-form-input
										id="name"
										v-model="deposit.username"
										trim
										disabled
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col>

						<!-- Currency -->
						<!-- <b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="currency"
								rules="required"
							>
								<b-form-group
									:label="$t('Currency')"
									label-for="transactionId"
								>
									<b-form-input
										id="currency"
										v-model="deposit.currency"
										trim
										disabled
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col> -->

						<!-- Amount -->
						<b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="amount"
								rules="required"
							>
								<b-form-group
									:label="`${$t('Amount')} (Gross)`"
									label-for="transactionId"
								>
									<b-form-input
										id="amount"
										v-model="deposit.reviewAmount"
										trim
										disabled
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col>

						<!-- Company Bank Account Code -->
						<!-- <b-col
							cols="12"
						>
							<b-form-group
								:label="$t('Company bank account code')"
								label-for="companyBankAccountCode"
							>
								<b-form-input
									id="companyBankAccountCode"
									v-model="deposit.companyBankAccountCode"
									trim
									disabled
								/>
							</b-form-group>
						</b-col> -->

						<!-- Member Bank Account Code -->
						<!-- <b-col
							cols="12"
						>
							<b-form-group
								:label="$t('Member bank account number')"
								label-for="memberBankAccountNumber"
							>
								<b-form-input
									id="memberBankAccountNumber"
									v-model="deposit.memberBankAccountNumber"
									trim
									disabled
								/>

							</b-form-group>
						</b-col> -->

						<!-- Member Bank Ref No. -->
						<!-- <b-col
							cols="12"
						>
							<b-form-group
								:label="$t('Member bank ref no')"
								label-for="memberBankRefNo"
							>
								<b-form-input
									id="memberBankRefNo"
									v-model="deposit.memberBankRefNo"
									trim
									disabled
								/>
							</b-form-group>
						</b-col> -->

						<!-- Charge Amount -->
						<!-- <b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="chargeAmount"
								rules="required"
							>
								<b-form-group
									:label="$t('Charge amount')"
									label-for="chargeAmount"
								>
									<b-form-input
										id="chargeAmount"
										v-model="deposit.chargeAmount"
										trim
										disabled
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col> -->

						<!-- Bill -->
						<!-- <b-col
							cols="12"
						>
							<b-form-group
								label-for="attachments"
							>
								<label for="">{{ $t('Bills') }} <span class="text-secondary">(Không > 10Mb)</span></label>
								<validation-provider
									#default="{ errors }"
									name="attachments"
									rules="size:10240"
								>
									<b-form-file
										v-model="attachments"
										multiple
										input-id="attachments"
										accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
								
							</b-form-group>
							<b-button
								v-show="attachments.length != 0"
								variant="outline-danger"
								class="btn-sm btn-icon"
								@click="attachments = []"
							>
								<feather-icon icon="XIcon"/>
							</b-button>
						</b-col> -->

						<!-- Tags 
						<b-col
							cols="12"
						>
							<b-form-group
								label="Tags"
								label-for="Tags"
							>
								<v-select
									v-model="deposit.tags"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="tagsLists"
									:reduce="val => val.id"
									label="name"
									input-id="tags"
								/>
							</b-form-group>
						</b-col> -->

						<!-- Remark -->
						<b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="remark"
							>
								<b-form-group
									label-for="remark"
								>
									<label for="remark"><span class="text-danger"></span> {{ $t('Remark') }}</label>
									<b-form-textarea
										id="remark"
										v-model="deposit.remark"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BForm, BFormFile, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { numberFormat, resolveVBank } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import tagStoreModule from '@/views/apps/user/tag/tagStoreModule'
import useUploadBill from '@/views/payments/upload-bill/useUploadBill'

export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge, BFormFile,
		BInputGroupAppend,
		BFormInvalidFeedback,
		ValidationProvider,
		ValidationObserver,
		vSelect,
		flatPickr,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		depositData: {
			type: [Object, null],
			required: false,
			default: null,
		}
	},
	data() {
		return {
			deposit: {
				id: null,
				reference: null,
				username: null,
				currency: null,
				amount: null,
				// companyBankAccountCode: null,
				// memberBankAccountNumber: null,
				// memberBankRefNo: null,
				// chargeAmount: null,
				// attachment: null,
				tags: null,
				status: 4,
				remark: null,
			},
			attachments: []
		}
	},
	watch: {
		depositData: {
			deep: true,
			handler(newVal) {
				if (newVal) {
					this.deposit = {
						id: newVal.id,
						reference: newVal.ref,
						username: newVal.user ? newVal.user.username : 'n/a',
						currency: newVal.currency,
						amount: newVal.amount / 1000,
						// chargeAmount: numberFormat(newVal.fee / 1000),
						// companyBankAccountCode: newVal.v_bank ? resolveVBank(newVal.v_bank.account, newVal.v_bank.bank.code) : '',
						// memberBankAccountNumber: newVal.account_number,
						// memberBankRefNo: newVal.bank_ref,
						reviewAmount: numberFormat(newVal.amount / 1000),
						status: 4,
					}
				}
			}
		}
	},
	methods: {
		validationFormApproveDeposit() {
			this.$refs.refFormObserver.validate().then(success => {
				if (success) {
					store.dispatch('payment-deposit/updateDepositStatus', this.deposit).then(
						response => {
							if (response.status === 200) {
								if (response.data.code == '00') {
									this.$emit('refetch-data')
									if (this.attachments.length > 0) {
										this.uploadBill({
											ref: this.deposit.reference,
											attachments: this.attachments
										})
									}
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message || 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$nextTick(() => {
										setTimeout(() => {
											this.$bvModal.hide('modal-approve-deposit')
											this.resetForm()
										}, '500')
									})
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
							}
						})
						.catch(error => {
							const { response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'Error Proccess',
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		confirmApproveDeposit(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormApproveDeposit()
		},
		resetForm() {
			this.$emit('update:depositData', null)
			this.attachments = []
		},
	},
	setup() {
		const toast = useToast()

		const tagsLists = ref([])

		const { uploadBill } = useUploadBill()

		// Register module
		if (!store.hasModule('app-user-tag')) store.registerModule('app-user-tag', tagStoreModule)
		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule('app-user-tag')) store.unregisterModule('app-user-tag')
		})

		store
			.dispatch('app-user-tag/fetchTagTypes', {})
			.then(response => {
				const { tagTypes } = response
				tagsLists.value = tagTypes
			})

		const remarkOptions = [
			{ label: 'Have fund', value: 1 },
			{ label: 'Have fund - just reveived from CS/TSM', value: 2 },
			{ label: 'Have fund - late reflected', value: 3 },
			{ label: 'Have fund - using third party - KYC Provided', value: 4 },
			{ label: 'Have fund - name', value: 5 },
			{ label: 'Have fund - bank', value: 6 },
			{ label: 'Have fund - bank error', value: 7 },
		]

		return {
			remarkOptions,
			tagsLists,
			uploadBill,
		}
	},
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
	max-height: 100% !important;
}
</style>
